<template>
  <div :class="{ 'border-bottom mb-2': showName }">
    <div v-if="showName" class="ui-type-heading text-primary">
      {{ variant.translations[lang].name }}
    </div>
    <CRow class="align-items-center">
      <CCol sm="6" lg="5" xl="4">
        <CInput
          v-model="variant.pw_min"
          :isValid="variant.isValid('pw_min')"
          :invalidFeedback="variant.getErrorMessage('pw_min')"
          :append="suffix"
        >
          <template #label>
            <strong>Quantità minima acquistabile</strong>
          </template>
        </CInput>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "ProductFormVariantMinQuantity",
  props: {
    variant: { type: Object, required: true },
    lang: { type: String, required: true },
    suffix: { type: String, default: undefined },
    showName: { type: Boolean, default: false },
  },
};
</script>
