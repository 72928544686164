<template>
  <div :class="{ 'border-bottom mb-2': showName }">
    <div v-if="showName" class="ui-type-heading text-primary">
      {{ variant.translations[lang].name }}
    </div>
    <CRow class="align-items-center">
      <CCol>
        <div class="form-group">
          <label
            ><strong>Disponibilità</strong><em class="text-danger">*</em></label
          >
          <UiCInputRadioGroup
            :options="availabilityLimitlessOptions"
            :checked.sync="variant.availability_limitless"
            custom
            inline
          />

          <small
            class="text-danger"
            v-if="!variant.isValid('availability_limitless')"
          >
            {{ variant.getErrorMessage("availability_limitless") }}
          </small>
        </div>
      </CCol>
    </CRow>
    <CRow class="align-items-center" v-if="!variant.availability_limitless">
      <CCol md="4">
        <CInput v-model="variant.availability_start" :append="suffix">
          <template #label>
            <strong>Quantità massima</strong>
            <em class="text-danger">*</em>
          </template>
        </CInput>
      </CCol>
      <CCol md="4">
        <CSelect
          :value.sync="variant.availability_xd"
          :options="availabilityXdOptions"
          :isValid="variant.isValid('availability_xd')"
          :invalidFeedback="variant.getErrorMessage('availability_xd')"
          custom
        >
          <template #label>
            <strong>Disponibile</strong>
            <em class="text-danger">*</em>
          </template>
        </CSelect>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UiCInputRadioGroup from "../../../../common/form/UiCInputRadioGroup";
export default {
  name: "ProductFormVariantAvailability",
  components: {
    UiCInputRadioGroup,
  },
  props: {
    variant: { type: Object, required: true },
    lang: { type: String, required: true },
    availabilityLimitlessOptions: Array,
    availabilityXdOptions: Array,
    suffix: { type: String, default: undefined },
    showName: { type: Boolean, default: false },
  },
};
</script>
