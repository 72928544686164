<template>
  <div>
    <div
      v-for="variant in variants"
      :key="variant.id"
      class="border-bottom mb-2"
    >
      <CRow>
        <CCol md="3" xl="4">
          <CInput
            required
            v-model="variant.translations[lang].name"
            :isValid="variant.isValid(`translations.${lang}.name`)"
            :invalidFeedback="
              variant.getErrorMessage(`translations.${lang}.name`)
            "
          >
            <template #label>
              <LabelHelp
                :required="true"
                header="Titolo variante"
                content="Metti una descrizione chiara e precisa. Esempi: ca. 1 Kg / ca. 500 g / 6 bottiglie / 500 ml."
              >
                Titolo variante
              </LabelHelp>
            </template>
          </CInput>
        </CCol>
        <CCol sm="4" md="3">
          <CInput
            v-model="variant.producer_code"
            :isValid="variant.isValid('producer_code')"
            :invalidFeedback="variant.getErrorMessage('producer_code')"
          >
            <template #label>
              <LabelHelp
                :required="false"
                header="Codice interno (SKU)"
                content="Usa il tuo codice di riconoscimento interno, comparirà sugli ordini che riceverai."
              >
                Codice interno (SKU)
              </LabelHelp>
            </template>
          </CInput>
        </CCol>
        <CCol sm="auto">
          <div class="form-group">
            <strong>Pronto per la vendita</strong>
            <div class="mt-2">
              <CSwitch
                variant="3d"
                size="sm"
                color="secondary"
                :checked.sync="variant.is_active"
              />
            </div>
          </div>
        </CCol>
        <CCol sm="auto" class="align-self-center ml-auto">
          <CButton
            variant="outline"
            color="danger"
            v-if="variant.can_be_deleted && canDelete"
            @click="onDeleteVariant(variant)"
            title="ELIMINA VARIANTE"
            ><font-awesome-icon icon="trash-alt"
          /></CButton>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import LabelHelp from "../../../../common/form/LabelHelp";
export default {
  name: "ProductFormVariants",
  components: {
    LabelHelp,
  },
  props: {
    variants: { type: Array, required: true },
    lang: { type: String, required: true },
    canDelete: { type: Boolean, default: true },
  },

  methods: {
    onDeleteVariant(variant) {
      this.$emit("onDelete", variant);
    },
  },
};
</script>
