<template>
  <CBadge v-if="!isActive()" shape="pill" color="gray-200">non attivo</CBadge>
  <div
    v-else
    class="d-inline-block"
    v-c-tooltip="{ html: true, content: content(), placement: 'left' }"
  >
    <span
      v-for="market in markets()"
      class="badge badge-bubble badge-pill"
      :class="{
        'badge-success': market.on_sell,
        'badge-info': market.on_approval,
      }"
      :key="market.id"
    ></span>
  </div>
</template>

<script>
import get from "lodash/get";
export default {
  name: "ProductTableVariantMarkets",

  methods: {
    isActive() {
      return (
        this.data.is_active !== false &&
        this.data.productproducer.is_active !== false
      );
    },
    markets() {
      const data = this.data;
      const isActive = this.isActive();
      const ppls = get(data, "ppls", []);
      return ppls.map((item) => {
        return {
          id: item.id,
          name: get(item, "market.role.name"),
          on_sell: isActive && item.active,
          on_approval: isActive && !item.active,
        };
      });
    },
    content() {
      const content = [];
      this.markets().forEach((m) => {
        const badge = `<span class="mr-1 badge badge-bubble badge-pill ${
          m.on_sell ? "badge-success" : ""
        } ${m.on_approval ? "badge-info" : ""}"></span>`;
        content.push(
          `<div class="d-flex align-items-center"> ${badge} ${m.name}: ${
            m.on_sell ? "in vendita" : ""
          }${m.on_approval ? "in approvazione" : ""}</div>`
        );
      });
      return content.join("<br />");
    },
  },
};
</script>
