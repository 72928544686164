<template>
  <div>
    <section class="mb-5">
      <CCard>
        <CCardBody>
          <ProductsTableListFilters @onSubmit="onSubmit" />

          <CRow>
            <CCol>
              <CDropdown
                togglerText="MODIFICA"
                color="primary"
                :disabled="actionsDisabled"
              >
                <CDropdownItem @click="activeSelected()">Attiva</CDropdownItem>
                <CDropdownItem @click="deactiveSelected()"
                  >Disattiva</CDropdownItem
                >
                <CDropdownDivider />
                <CDropdownItem @click="editPriceSelected()"
                  >Modifica prezzo</CDropdownItem
                >
                <CDropdownItem @click="editAvailabilitySelected()"
                  >Modifica disponibilità</CDropdownItem
                >
              </CDropdown>
            </CCol>
          </CRow>

          <ejs-grid
            ref="grid"
            :dataSource="dm"
            :allowSelection="true"
            :selectionSettings="selectionSettings"
            :allowSorting="true"
            :allowPaging="true"
            :rowDataBound="rowDataBound"
            :query="query"
            @checkBoxChange="onCheckboxChange"
          >
            <e-columns>
              <e-column type="checkbox" width="40" textAlign="left"></e-column>
              <e-column
                field="id"
                headerText=""
                isPrimaryKey="{true}"
                :template="imageTemplate"
                width="90"
                textAlign="Center"
              ></e-column>
              <e-column
                :template="nameTemplate"
                :field="`translations.${lang}.name`"
                headerText="Nome"
              ></e-column>
              <e-column
                :template="categoryTemplate"
                :field="`productproducer.category.translations.${lang}.name`"
                headerText="Categoria"
              ></e-column>
              <e-column
                field="price"
                :template="priceTemplate"
                headerText="Prezzo da incassare"
                width="240"
              ></e-column>
              <e-column
                :template="marketsTemplate"
                headerText="Mercati"
                width="180"
              ></e-column>
              <e-column
                :template="actionsTemplate"
                headerText="Duplica"
                width="180"
              ></e-column>
            </e-columns>
          </ejs-grid>
        </CCardBody>
      </CCard>
    </section>
  </div>
</template>

<script>
import { Sort, Page } from "@syncfusion/ej2-vue-grids";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { endPoint, GetDataManagerNew } from "../../../ds";
import {
  ProductTableVariantActions,
  ProductTableVariantCategory,
  ProductTableVariantImage,
  ProductTableVariantMarkets,
  ProductTableVariantPrice,
  ProductTableVariantTitle,
} from "./partials";
import ProductsTableListFilters from "./ProductsTableListFilters";

export default {
  name: "ProductsTableList",

  components: {
    ProductsTableListFilters,
  },

  provide: {
    grid: [Sort, Page],
  },

  data: function () {
    const dm = GetDataManagerNew("role_producer_products_lots_NEW", [
      this.$store.state.role.id,
    ]);
    return {
      dm: dm,
      query: new Query(),
      actionsDisabled: true,
      selectOptions: { persistSelection: true },
      selectionSettings: {
        persistSelection: true,
        enableToggle: true,
        checkboxOnly: true,
      },

      imageTemplate: () => {
        return { template: ProductTableVariantImage };
      },
      nameTemplate: () => {
        return { template: ProductTableVariantTitle };
      },
      categoryTemplate: () => {
        return { template: ProductTableVariantCategory };
      },
      priceTemplate: () => {
        return { template: ProductTableVariantPrice };
      },
      marketsTemplate: () => {
        return { template: ProductTableVariantMarkets };
      },
      actionsTemplate: () => {
        return { template: ProductTableVariantActions };
      },
    };
  },

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
  },

  methods: {
    onCheckboxChange() {
      const selected = this.$refs.grid.getSelectedRecords();
      this.actionsDisabled = selected.length === 0;
    },

    rowDataBound(args) {
      if (args.data["is_active"] !== true) {
        args.row.classList.add("ui-row-disabled");
      }
    },

    activeSelected() {
      const selected = this.$refs.grid.getSelectedRecords();
      if (selected.length > 0) {
        this.changeIsActive(selected, true);
      }
    },

    deactiveSelected() {
      const selected = this.$refs.grid.getSelectedRecords();
      if (selected.length > 0) {
        this.changeIsActive(selected, false);
      }
    },

    editPriceSelected() {
      const selected = this.$refs.grid.getSelectedRecords();
      if (selected.length > 0) {
        this.$store.commit("service/selectedVariants", selected);
        this.$router.push({
          name: "RoleProductsEditPrices",
          params: {
            id: this.$store.state.role.id,
          },
        });
      }
    },

    editAvailabilitySelected() {
      const selected = this.$refs.grid.getSelectedRecords();
      if (selected.length > 0) {
        this.$store.commit("service/selectedVariants", selected);
        this.$router.push({
          name: "RoleProductsEditAvailability",
          params: {
            id: this.$store.state.role.id,
          },
        });
      }
    },

    onSubmit(formData) {
      const lang = this.$i18n.locale;
      let query_v = [];
      if (formData.category_id) {
        query_v.push(
          new Predicate(
            "productproducer.category.id",
            "equal",
            formData.category_id
          )
        );
      }
      if (formData.logistic_type_id) {
        query_v.push(
          new Predicate(
            "productproducer.ptype_id",
            "equal",
            formData.logistic_type_id
          )
        );
      }
      if (formData.sell_id == "1") {
        // non attivo
        query_v.push(
          new Predicate("is_active", "equal", false).or(
            "productproducer.is_active",
            "equal",
            false
          )
        );
      }
      if (formData.sell_id == "2") {
        // in vendita
        query_v.push(
          new Predicate("is_active", "equal", true)
            .and("productproducer.is_active", "equal", true)
            .and("ppls.active", "equal", true)
        );
      }
      if (formData.sell_id == "3") {
        // in approvazione
        query_v.push(
          new Predicate("is_active", "equal", true)
            .and("productproducer.is_active", "equal", true)
            .and("ppls.active", "equal", false)
        );
      }
      // console.log(query_v)
      let predicate = null;
      for (var i = 0; i < query_v.length; i++) {
        if (i == 0) {
          predicate = query_v[i];
        } else {
          predicate = predicate.and(query_v[i]);
        }
      }

      let q = new Query();
      if (predicate) {
        q = q.where(predicate);
      }
      if (formData.q) {
        q = q.search(formData.q, [
          "translations.it.name",
          `productproducer.translations.${lang}.name`,
        ]);
      }
      this.query = q;
    },

    changeIsActive(selected, staus) {
      const self = this;
      const roleId = this.$store.state.role.id;
      const url =
        endPoint("role_producer_products_lots_NEW", [roleId]) + "multi_update/";

      const params = selected.map((row) => {
        return { id: row.id, is_active: staus };
      });

      const handleRequestErrors = (error) => {
        console.error(error.response.data);
      };

      this.axios
        .patch(url, params)
        .then(() => {
          self.$store.dispatch("toaster/add", {
            title: "Aggiornamento attivazione prodotti",
            text: `Prodotti ${
              status ? "attivati" : "disattivati"
            } con successo`,
            autohide: true,
            color: "success",
          });

          self.$refs.grid.refresh();
        })
        .catch(handleRequestErrors);
    },
  },
};
</script>
