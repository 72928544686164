<template>
  <div :class="{ 'border-bottom mb-2': showName }">
    <div v-if="showName" class="ui-type-heading text-primary">
      {{ variant.translations[lang].name }}
    </div>
    <CRow class="align-items-end">
      <CCol sm="6" md="5" lg>
        <CInput
          v-model="variant.price"
          :append="suffix"
          :isValid="variant.isValid('price')"
          :invalidFeedback="variant.getErrorMessage('price')"
        >
          <template #label>
            <label>
              <strong>Prezzo da incassare (IVA inc.)</strong>
              <em class="text-danger">*</em>
            </label>
          </template>
        </CInput>
      </CCol>
      <CCol sm="6" md="5" lg>
        <CInput
          v-model="variant.price_sugg"
          :append="suffix"
          :isValid="variant.isValid('price_sugg')"
          :invalidFeedback="variant.getErrorMessage('price_sugg')"
        >
          <template #label>
            <LabelHelp
              :required="false"
              header="Prezzo consigliato (IVA inc.)"
              content="Indica il prezzo al consumatore finale che consigli di fare o che sai essere di mercato."
            >
              Prezzo consigliato (IVA inc.)
            </LabelHelp>
          </template>
        </CInput>
      </CCol>
      <CCol sm="6" md="5" lg>
        <CSelect
          :value.sync="variant.tax_rate_id"
          :options="taxOptions"
          :disabled="taxOptions.length === 0"
          :isValid="variant.isValid('tax_rate_id')"
          :invalidFeedback="variant.getErrorMessage('tax_rate_id')"
          custom
        >
          <template #label>
            <strong>Aliquota IVA</strong>
            <em class="text-danger">*</em>
          </template>
        </CSelect>
      </CCol>
      <CCol sm="6" md="5" lg v-if="showWeightFields">
        <CInput
          v-model="variant.price_len"
          :isValid="variant.isValid('price_len')"
          :invalidFeedback="variant.getErrorMessage('price_len')"
        >
          <template #label>
            <strong>Peso a pezzo</strong>
            <em class="text-danger">*</em>
          </template>
        </CInput>
      </CCol>
      <CCol sm="6" md="5" lg v-if="showWeightFields">
        <CSelect
          :value.sync="variant.price_unit"
          :options="priceUnitOptions"
          :isValid="variant.isValid('price_unit')"
          :invalidFeedback="variant.getErrorMessage('price_unit')"
          custom
        >
          <template #label>
            <strong>Unità di misura</strong>
            <em class="text-danger">*</em>
          </template>
        </CSelect>
      </CCol>
      <CCol sm="6" md="5" lg v-if="showWeightFields">
        <div class="form-group">
          <CInputCheckbox
            label="Circa"
            :checked.sync="variant.price_len_mol"
            :isValid="variant.isValid('price_len_mol')"
            :invalidFeedback="variant.getErrorMessage('price_len_mol')"
            custom
          />
          <CInputCheckbox
            label="Fattura a peso"
            :checked.sync="variant.pw"
            :isValid="variant.isValid('pw')"
            :invalidFeedback="variant.getErrorMessage('pw')"
            custom
          />
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import LabelHelp from "../../../../common/form/LabelHelp";
export default {
  name: "ProductFormVariantPrice",
  components: {
    LabelHelp,
  },
  props: {
    variant: { type: Object, required: true },
    lang: { type: String, required: true },
    taxOptions: { type: Array, required: true },
    priceUnitOptions: { type: Array, required: true },
    suffix: { type: String, default: undefined },
    showWeightFields: { type: Boolean, default: true },
    showName: { type: Boolean, default: false },
  },
};
</script>
